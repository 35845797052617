<template>
  <div v-if="state === states.READY">
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
        <BasePageHeading>Invite Candidates by Email</BasePageHeading>
      </div>
    </BasePageHeader>

    <BaseWrapper>
      <div class="p-6 bg-white rounded-lg shadow">
        <form
          novalidate="novalidate"
          @submit.prevent
        >
          <div class="items-end justify-between w-full space-y-6 md:flex md:space-x-6 md:space-y-0">
            <label class="block text-sm md:w-1/5">
              <span class="text-gray-800">First Name</span>
              <input
                v-model="$v.candidate.firstName.$model"
                class="block w-full mt-1 bg-gray-100 form-input"
                autocomplete="given-name"
              >
              <ErrorsInline v-if="hasErrors">
                <span v-if="!$v.candidate.firstName.required">This field is required</span>
              </ErrorsInline>
            </label>

            <label class="block text-sm md:w-1/5">
              <span class="text-gray-800">Last Name</span>
              <input
                v-model="$v.candidate.surname.$model"
                class="block w-full mt-1 bg-gray-100 form-input"
                autocomplete="family-name"
              >
              <ErrorsInline v-if="hasErrors">
                <span v-if="!$v.candidate.surname.required">This field is required</span>
              </ErrorsInline>
            </label>

            <label class="block text-sm md:w-1/5">
              <span class="text-gray-800">Email</span>
              <input
                v-model="$v.candidate.email.$model"
                type="email"
                class="block w-full mt-1 bg-gray-100 form-input"
                autocomplete="email"
              >
              <ErrorsInline v-if="hasErrors">
                <span v-if="!$v.candidate.email.required">This field is required</span>
                <span v-if="!$v.candidate.email.email">Needs to be a valid email address</span>
              </ErrorsInline>
            </label>

            <div class="flex items-center w-2/5">
              <BaseButton
                class="flex-shrink-0"
                variant="inverse"
                @click="addCandidate"
              >
                Add Candidate
              </BaseButton>

              <span class="mx-2 text-sm text-gray-600">or</span>

              <UploadCSVButton
                class="flex-shrink-0"
                @uploadedCandidates="addCandidatesToPreview($event)"
              />
            </div>
          </div>
        </form>

        <div class="mt-12">
          <div class="flex items-center justify-end w-full mb-4">
            <a
              v-if="candidates.length"
              href="javascript:;"
              class="text-sm text-red-500 duration-150 hover:text-red-400"
              @click="deleteAllCandidates()"
            >
              <span class="hidden sm:inline-block">Delete all</span>
              <Icon
                view-box="0 0 24 24"
                class="w-4 h-4 ml-1"
              >
                <Bin />
              </Icon>
            </a>
          </div>

          <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div class="overflow-hidden border-b border-gray-100">
                  <table class="min-w-full border border-gray-100 divide-y divide-gray-200">
                    <thead class="bg-gray-100">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          First Name
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Last Name
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          class="relative px-6 py-3"
                        >
                          <span
                            class="sr-only"
                          >Edit</span>
                        </th>
                        <th
                          scope="col"
                          class="relative px-6 py-3"
                        >
                          <span
                            class="sr-only"
                          >Delete</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="!candidates.length">
                        <tr
                          v-for="(row, index) in 5"
                          :key="index"
                          :class="{'bg-white': index % 2 === 0, 'bg-gray-100': index % 2 !== 0 }"
                        >
                          <td class="px-6 py-4 whitespace-nowrap"></td>
                          <td class="px-6 py-4 whitespace-nowrap"></td>
                          <td class="px-6 py-4 whitespace-nowrap"></td>
                          <td class="px-6 py-4 whitespace-nowrap"></td>
                          <td class="px-6 py-4 whitespace-nowrap"></td>
                        </tr>
                      </template>
                      <JobInviteRow
                        v-for="(candidate, index) in candidates"
                        :key="index"
                        :row-index="index"
                        :rows-editing="rowsEditing"
                        :candidate="candidate"
                        :class="{'bg-white': index % 2 === 0, 'bg-gray-100': index % 2 !== 0 }"
                        @editing="editRow($event)"
                        @delete-row="deleteRow($event)"
                        @submit-row="stopEditingRow($event)"
                      />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="relative pt-12 text-right">
          <BaseButton
            class="flex-shrink-0"
            :loading="loading"
            @click="inviteCandidates"
          >
            Invite Candidates
            <template slot="iconRight">
              <Icon
                view-box="0 0 24 24"
                class="w-6 h-6 text-white fill-none"
              >
                <Send stroke-width="1.5" />
              </Icon>
            </template>
          </BaseButton>

          <ErrorsInline
            v-if="inviteError || $v.candidates.$anyError"
            class="right-0"
          >
            {{ inviteError }}
          </ErrorsInline>

          <div v-if="errors.length">
            <ErrorsInline
              v-for="(error, index) in errors"
              :key="index"
              class="relative"
            >
              {{ error }}
            </ErrorsInline>
          </div>
        </div>
      </div>
      <div
        v-if="invitationsLoading"
      >
        <h3 class="text-lg font-semibold mt-6 mb-4">Recently sent invitations</h3>
        <BaseLoader />
      </div>
      <div v-else-if="!invitationsLoading && invitations.length > 0">
        <h3 class="text-lg font-semibold mt-6 mb-4">Recently sent invitations</h3>

        <BaseTable>
          <BaseTHead>
            <tr>
              <BaseTHeadTh width="30%">
                Name
              </BaseTHeadTh>
              <BaseTHeadTh width="40%">
                Email
              </BaseTHeadTh>
              <BaseTHeadTh
                align="right"
                width="30%"
              >
                Invited
              </BaseTHeadTh>
            </tr>
          </BaseTHead>
          <BaseTBody>
            <tr
              v-for="(invitation, i) in invitations"
              :key="`invitation-${i}`"
            >
              <BaseTBodyTh>
                {{ invitation.name }}
              </BaseTBodyTh>
              <BaseTBodyTh>
                {{ invitation.email }}
              </BaseTBodyTh>
              <BaseTBodyTd align="right">
                {{ formatDate(parseISO(invitation.invitedAt)) }}
              </BaseTBodyTd>
            </tr>
          </BaseTBody>
        </BaseTable>
      </div>
    </BaseWrapper>
  </div>
  <div v-else-if="state === states.LOADING">
    <BasePageHeader>
      <div>
        <!-- Normally we don’t use ifs here, but we only want to sho the page
          heading if we have breadcrumbs -->
        <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
        <BasePageHeading v-if="breadcrumbs.length">
          Invite Candidates by Email
        </BasePageHeading>
        <BasePageHeading v-else></BasePageHeading>
      </div>
    </BasePageHeader>

    <Loader />

    <BaseWrapper
      v-if="processingCandidates"
      class="text-center"
    >
      <h2 class="text-xl font-medium">
        Sending tests
      </h2>
      <p class="mt-2 mb-4">
        Processing {{ processingBatchFrom }} to {{ processingBatchTo }} of {{ candidates.length }} candidates&hellip;
      </p>
      <p class="mt-2 mb-4">
        It may take some time to process, please leave this window open
      </p>
      <div v-if="processingBatchErrors.length">
        <ErrorsInline
          v-for="(error, index) in processingBatchErrors"
          :key="index"
          class="relative"
        >
          {{ error }}
        </ErrorsInline>
      </div>
    </BaseWrapper>
  </div>
  <div v-else-if="state === states.ERROR">
    <BaseErrorBlock />
  </div>
</template>

<script>
import Bin from '@components/Icons/Bin'
import ErrorsInline from '@components/ErrorsInline'
import Icon from '@components/Icons/Icon'
import JobInviteRow from '@components/Jobs/JobInviteRow'
import Loader from '@components/Loader'
import Send from '@components/Icons/Send'
import UploadCSVButton from '@components/Jobs/UploadCSVButton'

import jobsApi from '@api/jobs'
import organisationCandidatesApi from '@api/organisationCandidates'
import states from '@api/states'
import { mapGetters } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { parseISO } from 'date-fns'
import { formatDate } from '@utils/formatDate'

export default {
  page: {
    title: 'Invite Candidates by Email'
  },

  components: {
    Bin,
    ErrorsInline,
    Icon,
    JobInviteRow,
    Loader,
    Send,
    UploadCSVButton
  },

  mixins: [validationMixin],

  data() {
    return {
      states,
      formatDate,
      parseISO,

      assessment: null,

      // ---------
      loading: false,
      rowsEditing: [],

      // Invitations
      invitations: [],
      invitationsLoading: false,

      // Batch processing
      processingBatch: 0,
      processingCandidates: false,
      candidatesPerBatch: 50,
      processingBatchErrors: [],

      inviteError: null,
      // Manual Adding of candidate form
      hasErrors: false,
      errors: [],

      candidates: [],

      candidate: {
        firstName: null,
        surname: null,
        email: null
      },

      chosenExams: [],

      submitSuccess: false

    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId',
      haveExamSuitesLoaded: 'exams/haveExamSuitesLoaded',
      haveExamsLoaded: 'exams/haveExamsLoaded',
      examSuites: 'exams/examSuites',
      customExams: 'exams/exams',
      organisationName: 'organisations/name'
    }),

    /**
     * @return {Array}
     */
    breadcrumbs() {
      // Normally we don’t use breadcrumbs on loading pages, but we allow it
      // here for loading if the infomration is ready
      if (!this.organisationName || !this.assessment) {
        return []
      }

      return [
        {
          name: this.organisationName
        },
        {
          name: 'Assessments',
          to: 'client-assessments-list'
        },
        {
          name: this.assessment.job.name,
          to: 'client-assessments-show',
          params: {
            id: this.assessment.job.uuid
          }
        }
      ]
    },

    /**
     * @return {string}
     */
    state() {
      if (this.error) {
        return states.ERROR
      }
      if (!this.assessment) {
        return states.LOADING
      }
      if (this.processingCandidates) {
        return states.LOADING
      }

      return states.READY
    },

    /**
     * @return {Boolean}
     */
    isClean() {
      return !this.$v.candidate.$anyDirty
    },

    candidateBatches() {
      if (this.candidates.length === 0) {
        return null
      }

      return Math.ceil(this.candidates.length / this.candidatesPerBatch)
    },

    /**
     * @return {Number}
     */
    processingBatchFrom() {
      return this.processingBatch * this.candidatesPerBatch - this.candidatesPerBatch + 1
    },

    /**
     * @return {Number}
     */
    processingBatchTo() {
      const to = this.processingBatch * this.candidatesPerBatch + 1

      if (this.candidates.length < to) {
        return this.candidates.length
      }
      return to
    }
  },

  validations: {
    candidate: {
      firstName: {
        required
      },
      surname: {
        required
      },
      email: {
        required,
        email
      }
    },

    candidates: {
      $each: {
        firstName: {
          required
        },
        surname: {
          required
        },
        email: {
          required,
          email
        }
      }
    }
  },

  watch: {
    candidates() {
      if (this.candidates.length) {
        this.inviteError = null
      }
    }
  },

  created() {
    this.fetchAssessment()
    this.fetchInvitations()
  },

  beforeMount() {
    window.addEventListener('beforeunload', this.launchReloadWarning)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.launchReloadWarning)
    })
  },

  methods: {
    /**
     * Fetch the assessment (previously job) based on the ID
     */
    fetchAssessment() {
      this.assessment = null

      return jobsApi.job(this.$route.params.id)
        .then(response => {
          this.assessment = response
        })
        .catch(error => {
          this.error = error
          throw error
        })
    },

    /**
     * Fetch a list of invitations
     *
     * @return {void}
     */
    fetchInvitations() {
      if (this.invitationsLoading) {
        return
      }

      this.invitations = []
      this.invitationsLoading = true

      return jobsApi.invitations(this.$route.params.id)
        .then(invitations => {
          this.invitations = invitations
          this.invitationsLoading = false
        })
        .catch(error => {
          this.invitationsLoading = false
          throw error
        })
    },

    addCandidate() {
      this.$v.candidate.$touch()
      this.hasErrors = this.$v.candidate.$anyError
      if (this.hasErrors || this.isClean) {
        this.loading = false
        return
      }

      this.candidates.push(this.candidate)
      // Clear Inputs
      this.candidate = {
        firstName: null,
        surname: null,
        email: null
      }
    },

    editRow(row) {
      this.rowsEditing.push(row)
    },

    deleteRow(rowIndex) {
      this.candidates = this.candidates.filter((_, index) => index !== rowIndex)
    },

    stopEditingRow(row) {
      // Update candidate in array
      this.candidates.splice(row.index, 1, row.candidate)
      this.rowsEditing = this.rowsEditing.filter(item => item !== row.index)
    },

    deleteAllCandidates() {
      this.candidates = []
    },

    addCandidatesToPreview(uploadedCandidates) {
      this.candidates = [...this.candidates, ...uploadedCandidates]
    },

    inviteCandidates() {
      if (!this.candidates.length) {
        this.inviteError = 'You need to add some candidates first'
        return
      }

      if (this.rowsEditing.length) {
        this.inviteError = 'Please finish editing before submitting'
        return
      }

      if (!this.candidates.length) {
        this.inviteError = 'You need to add some candidates first'
        return
      }

      this.$v.candidates.$touch()
      if (this.$v.candidates.$anyError) {
        this.inviteError = 'Please check all names and emails for errors'
        return
      }

      this.loading = true
      this.processingCandidates = true

      this.startBatchInvite(1)
    },

    /**
     * Trigger the start of the invitation
     */
    startBatchInvite(batchNumber) {
      this.processingBatch = batchNumber

      const candidatesInBatch = this.candidatesInBatch(batchNumber)

      if (candidatesInBatch.length === 0) {
        console.log('Finished running batches')

        this.submitSuccess = true
        this.$emit('candidatesInvited')

        // Remove page refresh warning
        window.removeEventListener('beforeunload', this.launchReloadWarning)

        this.$router.push({
          name: 'client-assessments-show',
          params: {
            id: this.assessment.job.uuid
          }
        })

        return
      }

      console.log('Starting batch', batchNumber)

      this.sendInvitations(candidatesInBatch)
        .then(() => {
          batchNumber = batchNumber + 1
          this.startBatchInvite(batchNumber)
        })
    },

    /**
     * Send the invitations
     */
    sendInvitations(candidates) {
      return organisationCandidatesApi
        .inviteCandiatesBatch(this.organisationId, {
          candidates,
          jobUuid: this.assessment.job.uuid
        })
        .then(response => {
          return response
        })
        .catch(error => {
          if (error.response && error.response.data.errors) {
            console.error('Could not process batch')
            this.processingBatchErrors.push(error.response.data.errors)
            return
          }
          throw error
        })
    },

    /**
     * Returns candidates for a certain batch number
     *
     * @return {Array}
     */
    candidatesInBatch(batchNumber) {
      if (this.candidates.length === 0) {
        return null
      }

      let end = this.candidatesPerBatch * batchNumber
      let start = end - this.candidatesPerBatch

      return this.candidates.slice(start, end)
    },

    /**
     * Launch reload page generic browser alert
     */
    launchReloadWarning(event) {
      event.preventDefault()
      // Chrome requires returnValue to be set
      event.returnValue = ''
    }
  }
}
</script>
