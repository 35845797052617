<template>
  <BaseContainer>
    <AssessmentInvite />
  </BaseContainer>
</template>

<script>
import AssessmentInvite from '@components/ClientAssessments/AssessmentInvite'

export default {
  components: {
    AssessmentInvite
  }
}
</script>
